<template>
  <div data-testid="container-handle-event-invite">
    <v-card v-if="!inviteHashInfo && !loader">
      <v-card-text class="pt-6">
        <v-alert type="error">
          This event invite could not be processed.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          :color="Pallette.actionButtons.delete"
          to="/store/events"
          data-testid="btn-cancel"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="inviteHashInfo && !loader">
      <!--      <template v-if="!correctCompany">-->
      <!--        <v-card-text class="pt-6">-->
      <!--          <v-alert-->
      <!--            type="error"-->
      <!--          >-->
      <!--            Has no wright to access invite.-->
      <!--          </v-alert>-->
      <!--        </v-card-text>-->
      <!--        <v-card-actions>-->
      <!--          <v-spacer/>-->
      <!--          <v-btn text :color="Pallette.actionButtons.delete"-->
      <!--                 to="/store/events">cancel-->
      <!--          </v-btn>-->
      <!--        </v-card-actions>-->
      <!--      </template>-->
      <!--      <template v-if="correctCompany">-->
      <v-card-title>
        <span data-testid="title">
          Invite request for Event
          <span class="blue-grey--text text--lighten-4">{{
            inviteHashInfo.event ? inviteHashInfo.event.name : ""
          }}</span
          >, for role
          <span class="blue-grey--text text--lighten-4">{{
            inviteHashInfo.role ? inviteHashInfo.role.name : ""
          }}</span
          >, from company
          <span class="blue-grey--text text--lighten-4">{{
            inviteHashInfo.company ? inviteHashInfo.company.name : ""
          }}</span>
        </span>
      </v-card-title>
      <v-card-text class="pt-6">
        <v-form
          data-vv-scope="form-event-invite"
          data-testid="form-event-invite"
        >
          <v-toolbar>
            Please select engine
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-alert
            :value="true"
            type="error"
            v-if="errors.has('form-event-invite.engine_keys')"
            data-testid="event-invite-errors-engine-keys"
          >
            {{ errors.first("form-event-invite.engine_keys") }}
          </v-alert>
          <DataTableExtended
            v-model="selectedEngines"
            :headers="headers"
            :items="engines"
            item-key="_key"
            class="elevation-0 border"
            :calculate-widths="true"
            :loading="showTableLoader"
            :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
            sortable
            :multi-sort="false"
            :server-items-length="enginesCompanyListCount"
            v-on:init-table-data="getEnginesList"
            ref="refTable"
            show-select
            :single-select="true"
            data-testid="table-engines"
          >
            <template v-slot:top>
              <v-row class="pa-3">
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    :style="
                      $vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''
                    "
                    data-testid="filter-search"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div data-testid="engine-name">{{ item.name }}</div>
            </template>
            <template v-slot:[`item.serial_number`]="{ item }">
              <div data-testid="engine-serial-number">
                {{ item.serial_number }}
              </div>
            </template>
          </DataTableExtended>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          :color="Pallette.actionButtons.delete"
          @click="declineInvitation()"
          data-testid="btn-decline"
          >decline
        </v-btn>
        <v-btn
          color="green"
          text
          @click.stop="acceptInvitation()"
          data-testid="btn-accept"
          >accept
        </v-btn>
      </v-card-actions>
      <!--      </template>-->
    </v-card>
    <Confirm ref="confirmDeclineDialog"></Confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import "@/validators/engines/store/event-validator"
import DataTableExtended from "@/components/table/DataTableExtended.vue"
import Confirm from "@/components/Confirm"

export default {
  name: "event-invite-handle",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DataTableExtended,
    Confirm,
  },

  data() {
    return {
      selectedEngines: [],
      search: "",
      headers: [
        {
          text: "",
          value: "",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Serial number",
          value: "serial_number",
        },
      ],
      inviteHashInfo: null,
    }
  },

  watch: {
    search() {
      this.getEnginesList()
    },
  },

  mounted: function () {
    this.$store.dispatch("saveLoaderAction", true)
    this.getInviteHashInfo()
      .then(() => {
        this.$store.dispatch("saveLoaderAction", false).then(() => {
          this.getEnginesList()
        })
      })
      .catch(() => {
        this.inviteHashInfo = null
        this.$store.dispatch("saveLoaderAction", false)
      })
  },

  methods: {
    getEnginesList: function () {
      let self = this
      if (self.$refs.refTable) {
        let getParams = self.$refs.refTable.getTableServerParams()
        if (self.search !== "") getParams.search = self.search
        return self.$store.dispatch("enginesCompanyGetList", {
          params: getParams,
        })
      }
    },
    getInviteHashInfo() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$store
          .dispatch("inviteHashInfo", self.$route.params.hash)
          .then((result) => {
            self.inviteHashInfo = result.data.data
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    acceptInvitation() {
      let self = this
      self.$validator.errors.clear("form-event-invite")
      if (!self.selectedEngines.length) {
        self.$validator.errors.add({
          scope: "form-event-invite",
          field: "engine_keys",
          msg: "You must select engine",
        })
      }
      self.$validator.validateAll("form-event-invite").then((result) => {
        if (result) {
          self.$store
            .dispatch("acceptEventInvite", {
              link: self.$route.params.hash,
              engine_id: self.selectedEngines[0]._key,
            })
            .then(() => {
              self.$router.push({ path: `/store/events` }).catch((err) => {})
            })
        }
      })
    },
    declineInvitation() {
      let self = this
      self.$refs.confirmDeclineDialog
        .open("Warning", "Are you sure you want to decline this invitation?", {
          color: "red darken-4",
          width: 330,
        })
        .then((confirm) => {
          if (confirm) {
            self.$store
              .dispatch("declineEventInvite", { link: self.$route.params.hash })
              .then(() => {
                self.$router.push({ path: `/store/events` }).catch((err) => {})
              })
          }
        })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      engines: "getEnginesCompanyList",
      enginesCompanyListCount: "enginesCompanyListCount",
      Pallette: "get_Pallette",
      loader: "getShowLoader",
      currentUser: "currentUser",
    }),
    correctCompany() {
      // @todo check if user company is correct
      return (
        this.inviteHashInfo &&
        this.inviteHashInfo.company._key === this.currentUser.group_key
      )
    },
  },
}
</script>
