import { Validator } from "vee-validate"
import store from "@/store"

Validator.extend("uniqueMembersEvent", {
  validate: (value) => {
    var members = store.getters.activeEvent.members
    return !members.find((m) => m.engine_key === value._key)
  },
  getMessage: () => "This engine already added as member in event.",
})

Validator.extend("uniqueMembersEventLocation", {
  validate: (value) => {
    var members = store.getters.activeEvent.members
    return !members.find((m) => m.engine_key === value?.engine?._key)
  },
  getMessage: () => "Engine from location already added as member in event.",
})

Validator.extend("requiredAdvanced", {
  validate: (value, args) => {
    let advanced = args[0] === "true" ? true : false
    let type = args[1]
    return (value && advanced && ["engine", "role"].includes(type)) ||
      (value && !advanced && type === "location")
      ? true
      : false
  },
  getMessage: (field, args) => `The ${args[1]} field is required.`,
})
